export default class RemoveFields {
  // This executes when the function is instantiated.
  constructor() {
    this.iterateLinks()
  }

  iterateLinks() {
    document.addEventListener('click', e => {
      if (e.target && (e.target.className == 'bx bx-trash' || e.target.className == "remove_fields")) {
        this.handleClick(e.target, e)
      }
    })
  }

  handleClick(link, e) {
    if (!link || !e) return

    e.preventDefault()
    let fieldParent = link.closest('.perform-remove')
    let deleteField = fieldParent
      ? fieldParent.querySelector('.trash input[type="hidden"]')
      : null

    if (deleteField) {
      deleteField.value = 1
      fieldParent.style.display = 'none';//
      fieldParent.querySelector('[required]')?.removeAttribute("required")
    }
  }
}
