import Trix from 'trix';

window.Trix = Trix; // Don't need to bind to the window, but useful for debugging.
Trix.config.toolbar.getDefaultHTML = toolbarDefaultHTML;

// Trix.config.blockAttributes.alignLeft = {
//   tagName: 'align-left',
// };

// Trix.config.blockAttributes.textAlignCenter = {
//   tagName: 'align-center',
// };

// Trix.config.blockAttributes.alignRight = {
//   tagName: 'align-right',
// };

Trix.config.textAttributes.foregroundColor = {
  styleProperty: 'color',
  inheritable: true
};
Trix.config.textAttributes.fontSize = {
  styleProperty: "font-size",
  inheritable: 1
};
Trix.config.textAttributes.underline = {
  style: { "textDecoration": "underline" },
  inheritable: true,
  parser: (element) => element.style.textDecoration === "underline",
}

document.addEventListener('trix-initialize', initTrix);

function initTrix(event) {
  const editor = event.target.editor;
  const toolbars = document.querySelectorAll('trix-toolbar');
  const html = Trix.config.toolbar.getDefaultHTML();
  toolbars.forEach((toolbar) => (toolbar.innerHTML = html));
  addEventListenerToTrixEditor(editor);
}

async function addEventListenerToTrixEditor(editor) {
  document.getElementById('foregroundColorPickerBtn').addEventListener(
    'click',
    () => document.getElementById('foregroundColorPicker').click()
  );
  document.getElementById('foregroundColorPicker').addEventListener(
    'input',
    (colorPickerEvent) => { editor.activateAttribute('foregroundColor', colorPickerEvent.target.value) }
  );
  
  const fontSizeInput = document.getElementById('fontSizeInput');
  fontSizeInput.addEventListener(
    'click',
    () => {
      editor.activateAttribute("frozen");
      fontSizeInput.focus()
    }
  );
  fontSizeInput.addEventListener(
    'keydown',
    (event) => {
      if (event.key !== 'Enter' || !event.target.value) return
      
      event.preventDefault();
      editor.activateAttribute('fontSize', `${event.target.value}px`);
      editor.deactivateAttribute('frozen');
    }
  );
}


/**
 * This is the default Trix toolbar. Feel free to change / manipulate it how you would like.
 * see https://github.com/basecamp/trix/blob/main/src/trix/config/toolbar.coffee
 */
function toolbarDefaultHTML() {
  const { lang } = { 
    lang: {
      bold: '太字',
      italic: '斜体',
      strike: '取り消し線',
      underline: '下線',
      heading1: 'タイトルにする',
      quote: '引用',
      numbers: '番号付きリスト',
      link: 'リンク',
      url: 'URL',
      urlPlaceholder: 'httpかhttps付きURL',
      addLink: '追加',
      removeLink: '削除',
      bullets: '箇条書き',
      indent: 'インデントを増やす',
      outdent: 'インデントを減らす',
      attachFiles: 'ファイル添付',
      undo: '元に戻す',
      redo: 'やり直し',
    }
  };

  return `
  <div class="trix-button-row">
    <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
      <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">${lang.bold}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${lang.italic}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-strike" data-trix-attribute="strike" title="${lang.strike}" tabindex="-1">${lang.strike}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-underline" data-trix-attribute="underline" data-trix-key="u" title="underline" tabindex="-1">${lang.underline}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1">${lang.link}</button>
      
      <input type="color" id="foregroundColorPicker" style="width:0;height:0;padding:0;margin-top:20px;visibility:hidden">
      <button type="button" id="foregroundColorPickerBtn" class="trix-button" style="width: 3.2em;" title="Text color">
        <span class="icon"><i class="bi bi-palette"></i></span>
      </button>

      <input type="number" id="fontSizeInput" placeholder="フォントサイズ">
    </span>

    <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
      <button type="button" class="trix-button trix-button--icon trix-button--icon-heading-1" data-trix-attribute="heading1" title="${lang.heading1}" tabindex="-1">${lang.heading1}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" title="${lang.quote}" tabindex="-1">${lang.quote}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1">${lang.bullets}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1">${lang.numbers}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="${lang.indent}" tabindex="-1">${lang.indent}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="${lang.outdent}" tabindex="-1">${lang.outdent}</button>
    </span>

    <span class="trix-button-group trix-button-group--file-tools" data-trix-button-group="file-tools">
      <button type="button" class="trix-button trix-button--icon trix-button--icon-attach" data-trix-action="attachFiles" title="${lang.attachFiles}" tabindex="-1">${lang.attachFiles}</button>
    </span>

    <span class="trix-button-group-spacer"></span>

    <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
      <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="${lang.undo}" tabindex="-1">${lang.undo}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="${lang.redo}" tabindex="-1">${lang.redo}</button>
    </span>
   </div>

   <div class="trix-dialogs" data-trix-dialogs>
     <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
       <div class="trix-dialog__link-fields">
         <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="${lang.urlPlaceholder}" aria-label="${lang.url}" data-trix-input>
         <div class="trix-button-group">
           <input type="button" class="trix-button trix-button--dialog" value="${lang.addLink}" data-trix-method="setAttribute">
           <input type="button" class="trix-button trix-button--dialog" value="${lang.removeLink}" data-trix-method="removeAttribute">
         </div>
       </div>
     </div>
   </div>
`;
}

{/* <span class="trix-button-group trix-button-group--alignment-tools">
  <button type="button" class="trix-button trix-button--icon trix-button--icon-align-left" data-trix-attribute="alignLeft" title="Align Left" tabindex="-1">Align Left</button>
  <button type="button" class="trix-button trix-button--icon trix-button--icon-align-center" data-trix-attribute="textAlignCenter" title="Align Center" tabindex="-1">Align Center</button>
  <button type="button" class="trix-button trix-button--icon trix-button--icon-align-right" data-trix-attribute="alignRight" title="Align Right" tabindex="-1">Align Right</button>
</span> */}
