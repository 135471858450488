// Entry point for the build script in your package.json
// This file will run only once. Plz only do imports here and use the imported module in HTML <script>.
import "@hotwired/turbo-rails"
import "@rails/actiontext"
import * as toastr from "toastr"
import AddFields from './nested-forms/AddFields'
import RemoveFields from './nested-forms/RemoveFields'
import './action-text/action-text'
global.prevMarker = undefined;
global.googleMap = undefined;
global.toastr = toastr
global.AddFields = AddFields
global.RemoveFields = RemoveFields
